import { CarouselController } from "~/controllers/carousel_controller"

export default class extends CarouselController {
  options = {
    cellAlign: "left",
    adaptiveHeight: false,
    autoPlay: this.autoplay,
    draggable: this.draggable,
    fade: false,
    pageDots: false,
    prevNextButtons: false,
    setGallerySize: false,
    wrapAround: true,
    selectedAttraction: 0.05,
    friction: 0.8,
    arrowShape: {
      x0: 25,
      x1: 65,
      y1: 40,
      x2: 65,
      y2: 40,
      x3: 25
    }
  }

  onFlickitySelect(index) {
    super.onFlickitySelect(index)
    this.arrowColor = this.currentSlideStyle.getPropertyValue("--color")
  }

  get autoplay() {
    if (!this.isMobile) return false
    return 4000
  }

  get draggable() {
    return this.isMobile
  }

  get isMobile() {
    return document.body.classList.contains("mobile")
  }

  set arrowColor(value) {
    this.element.style.setProperty("--arrow-color", value)
  }

  get currentSlideStyle() {
    return getComputedStyle(this.currentSlide)
  }
}
