import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static targets = ["thumbnail"]
  static debounces = ["compact"]
  static values = {
    compact: Boolean
  }

  connect() {
    useDebounce(this)
    this.compact()
  }

  compact() {
    this._reset()
    if (!this.shouldCompact) return
    const rowGap = this.rowGap
    this.gridElement.style.gridRowGap = 0
    for (const thumbnail of this.thumbnailTargets) {
      let distance = this._distanceAbove(thumbnail)
      if (!distance) continue
      thumbnail.style.transform = `translateY(${distance.y + rowGap}px)`
      this.element.style.height = `${this.thumbnailsHeight}px`
    }
  }

  _reset() {
    this.element.style.height = null
    this.gridElement.style.gridRowGap = null
    for (const thumbnail of this.thumbnailTargets) {
      thumbnail.style.transform = null
    }
  }

  _distanceAbove(element) {
    return this.previousSiblings(element)
      .map(previous => this._relationBetween(element, previous))
      .filter(r => r.overlaps)
      .sort((a, b) => b.distance.y - a.distance.y)
      .map(r => r.distance)[0]
  }

  previousSiblings(element) {
    const siblings = []
    while ((element = element.previousElementSibling)) {
      siblings.push(element)
    }
    return siblings
  }

  _relationBetween(a, b) {
    const aRect = a.getBoundingClientRect()
    const bRect = b.getBoundingClientRect()
    return {
      a: a,
      b: b,
      overlaps: this._rectanglesOverlap(aRect, bRect),
      distance: {
        y: bRect.bottom - aRect.top,
        x: bRect.left - aRect.left
      }
    }
  }

  _rectanglesOverlap(aRect, bRect) {
    const tolerance = 2
    const aLeft = aRect.left + tolerance
    const aRight = aRect.right - tolerance
    const bLeft = bRect.left + tolerance
    const bRight = bRect.right - tolerance
    return (
      (bLeft >= aLeft && bLeft <= aRight) || // smaller
      (bLeft <= aLeft && bRight >= aRight) || // bigger
      (bLeft <= aLeft && bRight >= aLeft && bRight <= aRight) || // left overlap
      (bLeft >= aLeft && bLeft <= aRight && bRight >= aRight) || // right overlap
      (bLeft >= aLeft && bLeft <= aRight) ||
      (bRight >= aLeft && bRight <= aRight)
    )
  }

  get shouldCompact() {
    return this.compactValue && this.gridColumns > 1
  }

  get gridColumns() {
    return this.gridStyles.getPropertyValue("grid-template-columns").split(" ")
      .length
  }

  get rowGap() {
    return parseInt(this.gridStyles.getPropertyValue("grid-row-gap"), 10)
  }

  get gridStyles() {
    return window.getComputedStyle(this.gridElement)
  }

  get gridElement() {
    return this.element.querySelector(".Grid")
  }

  get thumbnailsHeight() {
    const lastTwoThumbnails = this.thumbnailTargets.slice(-2)
    const bottom = lastTwoThumbnails
      .map(t => t.getBoundingClientRect().bottom)
      .sort((a, b) => b - a)[0]
    return bottom - this.thumbnailTargets[0].getBoundingClientRect().top
  }
}
