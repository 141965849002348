import { Controller } from "@hotwired/stimulus"
import OpenSeadragon from "openseadragon"

export default class extends Controller {
  static values = {
    tileSources: Object
  }
  static classes = ["dragging"]

  connect() {
    this.element[this.identifier] = this
    this.viewer = OpenSeadragon({
      element: this.element,
      showNavigationControl: false,
      tileSources: this.tileSourcesValue,
      minZoomLevel: 1.0,
      defaultZoomLevel: 1.0,
      constrainDuringPan: true,
      visibilityRatio: 1.0,
      springStiffness: 4.0,
      animationTime: 0.2
    })
    this.viewer.addHandler("canvas-drag", () => {
      this.element.classList.add(this.draggingClass)
    })
    this.viewer.addHandler("canvas-drag-end", () => {
      this.element.classList.remove(this.draggingClass)
    })
  }

  disconnect() {
    if (this.viewer) this.viewer.destroy()
  }

  zoomIn() {
    this.viewport.zoomBy(2.0 / 1.0)
    this.viewport.applyConstraints()
  }

  zoomOut() {
    this.viewport.zoomBy(1.0 / 2.0)
    this.viewport.applyConstraints()
  }

  reset() {
    this.viewport.goHome()
  }

  onCanvasDrag(event) {}

  onCanvasDragEnd(event) {}

  get viewport() {
    return this.viewer.viewport
  }
}
