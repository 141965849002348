import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["shrink", "submenuVisible", "searchOpen", "open"]
  static targets = ["submenu"]

  initialize() {
    this.scheduledAnimationFrame = false
    this.shrinkAfter = 20
  }

  shrink() {
    if (!this.isSubmenuVisible) return
    if (this.scheduledAnimationFrame) return
    const currentScroll = window.scrollY
    const isPastShrinkPoint = currentScroll > this.shrinkPoint
    this.scheduledAnimationFrame = true
    requestAnimationFrame(() => this.toggleShrinkClass(isPastShrinkPoint))
    this.scheduledAnimationFrame = false
  }

  toggle() {
    this.element.classList.toggle(this.openClass)
    // this.toggleBodyScroll()
  }

  toggleShrinkClass(toggle) {
    this.element.classList.toggle(this.shrinkClass, toggle)
  }

  onSearchToggle(event) {
    this.element.classList.toggle(this.searchOpenClass, event.detail.open)
  }

  get isSubmenuVisible() {
    return this.submenuTargets.some(element => this._isVisible(element))
  }

  get shrinkPoint() {
    return (
      this.page.getBoundingClientRect().top + window.scrollY + this.shrinkAfter
    )
  }

  get page() {
    return this.element.parentElement.parentElement
  }

  _isVisible(element) {
    return !this._isHidden(element)
  }

  _isHidden(element) {
    const styles = window.getComputedStyle(element)
    return styles.display === "none" || styles.visibility === "hidden"
  }
}
