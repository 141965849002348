import { Controller } from "@hotwired/stimulus"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

export default class extends Controller {
  static targets = ["openSeaDragon"]

  connect() {
    disableBodyScroll(this.element)
  }

  disconnect() {
    enableBodyScroll(this.element)
  }

  reset() {
    this.openSeaDragon.reset()
  }

  zoomIn() {
    this.openSeaDragon.zoomIn()
  }

  zoomOut() {
    this.openSeaDragon.zoomOut()
  }

  close() {
    this.element.remove()
  }

  get openSeaDragon() {
    return this.openSeaDragonTarget["open-sea-dragon-component"]
  }
}
