import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static debounces = ["reset"]

  connect() {
    useDebounce(this)
    this._observe()
    // this.toggleStuck(this.element.getBoundingClientRect().y == this.top)
  }

  disconnect() {
    this._unobserve()
  }

  reset() {
    this._unobserve()
    this._observe()
  }

  _observe() {
    this.intersectionObserver = new IntersectionObserver(
      ([e]) => {
        this.toggleStuck(e.intersectionRatio < 1)
      },
      {
        rootMargin: this.rootMargin,
        threshold: [1]
      }
    )

    this.intersectionObserver.observe(this.element)
  }

  _unobserve() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect()
    }
  }

  toggleStuck(isStuck) {
    this.element.classList.toggle("sticky--stuck", isStuck)
    const eventName = isStuck ? "stuck" : "unstuck"
    const event = new Event(eventName)
    this.element.dispatchEvent(event)
  }

  get rootMargin() {
    return `${this.top * -1 - 1}px 0px 0px 0px`
  }

  get top() {
    return parseInt(this.style.top, 10)
  }

  get style() {
    return getComputedStyle(this.element)
  }
}
