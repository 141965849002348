import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fullscreenMap", "fullscreenMapTemplate"]

  loadFullscreenMap() {
    const template = this.fullscreenMapTemplateTarget
    const fullscreenMap = template.content.cloneNode(true)
    document.body.appendChild(fullscreenMap)
  }
}
