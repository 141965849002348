import { application } from "./application"
import { registerControllers } from "stimulus-vite-helpers"
import { registerControllers as registerLayoutControllers } from "@asgerb/apparatus-layout"
import { registerControllers as registerFoundationControllers } from "@architecture-of-territory/architecture_of_territory-foundation"

const controllers = import.meta.glob("./**/*_controller.js", { eager: true })
const componentControllers = import.meta.glob(
  "../../components/**/*_controller.js",
  { eager: true }
)
registerControllers(application, controllers)
registerControllers(application, componentControllers)
registerLayoutControllers(application)
registerFoundationControllers(application)
